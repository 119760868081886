@import "./variables";

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  font-family: 'Open Sans', 'Rancho';
  font-size: var(--font-size-normal);
  margin: 0;
}

body {
  background: white;
}