@import "../../scss/_mixins.scss";

.mainPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .pageHeader {
    font-size: 2rem;
    font-weight: bold;
    color: var(--shishya-blue);
    margin-top: 1rem;

    @include breakpoint(mobile) {
      margin-top: 4rem;

    }
  }

  .mainContent {
    margin-top: 2rem;
    max-width: 70vw;
    display: flex;
    flex-direction: column;

    .textContent {
      font-size: 1rem;

      @include breakpoint(mobile) {
        font-size: 1.3rem;
      }
    }

    @include breakpoint(mobile) {
      max-width: 90vw;

    }
  }
}
