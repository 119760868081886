:root {
  --shishyaa-turquoise: #a3f7f6;
  --shishya-pink: #fab1dc;
  --shishya-blue: #86bef7;
  --font-family-body: "Open Sans", Rancho, sans-serif;

  --font-size-xsmall: 0.75rem;
  --font-size-small: 0.875rem;
  --font-size-normal: 1rem;
  --font-size-medium: 1.25rem;
  --font-size-h1: 3rem;
  --font-size-h1-large: 4.25rem;

  --font-weight-normal: 400;
  --font-weight-bold: 600;
  --body-width-desktopLarge: 70vw;
  --body-width-desktop: 80vw;
  --body-width-mobile: 100vw;
}