@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', 'Rancho', sans-serif;
  font-weight: 500;

  .body {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    max-width: 1200px;
    margin: auto;

    .teamBannerPhoto {
      max-width: inherit;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }

      @include breakpoint(mobile) {
        width: 90vw;
      }
    }

    .teamMainContent {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      font-size: 1.2rem;
      max-width: inherit;
      margin-top: 1.5rem;

      .teamTitle {
        text-align: center;
        text-decoration: underline;
        font-size: 2rem;
        max-width: inherit;
      }

      .teamIntroductionText {
        font-size: 1.4rem;
        text-align: center;
      }

      .founderContent {
        max-width: inherit;
        .founderTitle {
          font-size: 2rem;
          text-align: center;
          text-decoration: underline;
        }

        @include breakpoint(mobile) {
          width: 100vw;
        }
      }

      .staffContent {
        max-width: inherit;
        .staffTitle {
          font-size: 2rem;
          text-align: center;
          text-decoration: underline;
        }

        @include breakpoint(mobile) {
          width: 100vw;
        }
      }

      .teamMembers {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        .teamMember {
          width: 100%;
          display: flex;
          flex-flow: column wrap;
          justify-content: flex-start;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 4rem;
          }

          .photo {
            width: 17rem;
            height: 10rem;

            @include breakpoint(mobile) {
              width: 70vw;
              height: 50vh;
            }

            @include breakpoint(desktop) {
              width: 17rem;
              height: 20rem;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .memberInfo {
            font-size: 1rem;
            margin-top: 1rem;

            .memberName {
              font-weight: 800;
              text-align: center;
              font-size: 1.2rem;
            }

            .memberQualification {
              margin-bottom: 1rem;
            }
          }

          @include breakpoint(mobile) {
            flex-flow: column nowrap;
          }

          @include breakpoint(desktop) {
            flex-flow: row nowrap;
          }
        }
      }

      @include breakpoint(mobile) {
        width: 95vw;
      }
    }
  }
}