@import "../../scss/_mixins.scss";

.overlayNavigationContainer {
  width: 15rem;
  height: 20rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;

  .imageContainer {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 2rem;

      @include breakpoint(mobile) {
        border-radius: 1rem;
      }
    }
  }

  .overlayTextContainer {
    position: absolute;
    margin-top: 2rem;
    .overlayTextValue {
      font-weight: 500;
      font-size: 1.5rem;

      @include breakpoint(mobile) {
        font-weight: 800;
        font-size: 2.5vw;
      }
    }
  }

  @include breakpoint(mobile) {
    width: 30%;
    height: 12rem;
    border-radius: 0.5rem;
  }

  @include breakpoint(tablet) {
    width: 30%;
    height: 12rem;
    border-radius: 0.5rem;
  }
}