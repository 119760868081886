@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Rancho';
  font-weight: 500;

  .body {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;

    .banner {
      max-width: 1200px;
      @include breakpoint(desktop) {
        width: 70vw;
      }
      @include breakpoint(mobile) {
        width: 95vw;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

    .mainContent {
      width: 100%;
      max-width: 1200px;

      @include breakpoint(desktop) {
        width: 70vw;
      }

      .intro {
        font-size: 1rem;
        padding: 2rem;
      }
    }

    .curriculumBody {
      width: 100%;

      .heading {
        font-weight: 800;
        font-size: 1.5rem;
        color: var(--shishya-pink);
        text-align: center;

        @include breakpoint(desktop) {
          margin: 1rem 0;
        }
      }

      .curriculumContent {
        display: flex;
        flex-flow: column wrap;

        .highlightContent {
          margin: 1rem 0;
          text-align: center;
          background-color: var(--shishyaa-turquoise);
          padding: 1rem;

          p {
            margin: 0;
          }
        }
      }

      .splitContent {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        margin-bottom: 1rem;
        max-width: 1200px;

        .imageContent {
          width: 50%;

          img {
            width: 100%;
            height: 100%;
          }

          @include breakpoint(mobile) {
            width: 100%;
          }
        }

        .textContent {
          width: 50%;
          padding: 1rem;

          ul {
            list-style-type: none;
          }

          ul li.turquoiseBullet::before {
            content: '\2022';
            font-weight: bold;
            color: var(--shishyaa-turquoise);
            margin-right: 1rem;
            font-size: 20px;
          }

          ul li.blueBullet::before {
            content: '\2022';
            font-weight: bold;
            color: var(--shishya-blue);
            margin-right: 1rem;
            font-size: 20px;
          }

          ul li.pinkBullet::before {
            content: '\2022';
            font-weight: bold;
            color: var(--shishya-pink);
            margin-right: 1rem;
            font-size: 20px;
          }

          @include breakpoint(mobile) {
            width: 100%;

            strong {
              font-size: 1.2rem;
            }
          }
        }

        @include breakpoint(mobile) {
          flex-flow: column wrap;

          &:nth-child(odd) {
            flex-flow: column-reverse wrap;
          }
        }
      }
    }
  }
}