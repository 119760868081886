@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', 'Rancho';
  font-weight: 500;

  .body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    max-width: 1200px;
    margin: auto;

    .admissionBannerPhoto {
      img {
        width: 100%;
        height: 100%;
      }

      @include breakpoint(desktop) {
        width: 75vw;
      }

      @include breakpoint(mobile) {
        width: 100vw;
        margin-top: 5rem;
      }
    }

    .admissionsMainContent {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      max-width: 1200px;

      .admissionsTitle {
        text-align: center;
        color: var(--shishya-pink);
        text-decoration: underline;
        font-size: 3rem;
      }

      .admissionsContent {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        width: 100%;

        .eligibilityCriteria {
          display: flex;
          flex-flow: column wrap;
          justify-content: center;
          align-items: center;
          width: 100%;

          table {
            border-collapse: separate;
            border-spacing: 15px;
          }
        }
      }

      .admissionsFooter {
        .admissionsContact {
          display: flex;
          flex-flow: column;
          justify-content: center;
          background-color: var(--shishyaa-turquoise);
          align-items: center;
        }
      }

      @include breakpoint(desktop) {
        width: 50vw;
      }

      @include breakpoint(mobile) {
        width: 95vw;
      }
    }

    .form {
      @include breakpoint(mobile) {
        width: 95vw;
      }

      @include breakpoint(tablet) {
        width: 95vw;
      }

      @include breakpoint(default) {
        width: 40vw;
        height: 1000px;
      }
    }
  }
}
