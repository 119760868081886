@import "../../scss/_mixins.scss";

.imgContainer {
  height: auto;
  display: flex !important;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
    max-width: 1200px;
    object-fit: cover;
    overflow: hidden;
  }
}

.swiper-slide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
