@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', 'Rancho', sans-serif;
  font-weight: 500;

  .body {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    max-width: 1200px;
    margin: auto;

    .founderTitle {
      font-size: 2.5rem;
      font-weight: 800;
      text-align: center;
      color: var(--shishya-pink);
      text-decoration: underline;
    }

    .teamIntroductionText {
      font-size: 1.2rem;
      text-align: center;
    }

    .founderDetails {
      display: flex;
      margin: auto;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 1.8rem;
        font-weight: bold;
      }

      .qualification {
        font-size: 1rem;
        font-weight: 600;
      }

      img {
        max-height: 400px;
      }

      .bio {
        margin-top: 1rem;
      }
    }
  }

}
