@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', 'Rancho';
  font-weight: 500;

  .body {
    display: flex;
    flex-flow: column nowrap;
    width: 100vw;
    justify-content: center;
    align-items: center;
    width: 100vw;

    .eventsContent {
      width: 50vw;
      max-width: 1200px;
      margin: auto;
      height: fit-content;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: center;

      .eventTitle {
        text-align: center;
        margin-top: 2rem;
      }

      .imgContainer {
        width: 100%;
        height: 90vh;
        margin: 50px 0px;

        img {
          width: 100%;
          height: 100%;
        }
        @include breakpoint(mobile) {
          width: 100%;
          height: auto;
        }

        @include breakpoint(desktop) {
          width: 75vw;

        }
      }

      .eventContent {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 5rem;

        .eventText {
          font-family: "Open Sans", "Rancho", sans-serif;
          font-size: 1rem;
        }
      }

      .table {
        width: 100%;
        text-align: center;
        border: 1px solid black;

        .mainTableRow {
          border: 1px solid black;

          .headerCol {
            font-weight: bold;
          }
        }

        .nestedTable {
          width: 100%;
          text-align: center;
        }

        .nestedTableContainer {
          padding: 0;
          margin: 0;

          td {
            border: 0;
          }

          tr:not(:last-child){
            border-bottom: 1px solid black;
          }
        }

        td {
          border: 1px solid black;
        }
      }

      @include breakpoint(mobile) {
        width: 95vw;
        margin-top: 2rem;
      }

      @include breakpoint(tablet) {
        width: 95vw;
        margin-top: 2rem;
      }
    }
  }
}
