@import "../../scss/_mixins.scss";

.mainPage {
  padding: 0px;
  margin: 0px;
  font-family: 'Open Sans', 'Rancho';
  font-weight: 500;

  .body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: auto;

    .missionPageContent {
      display: flex;
      flex-flow: column wrap;
      max-width: 1200px;
      margin: auto;

      .missiongPageBanner {
        width: 100%;
        height: 50%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .missionContentOne {
        width: 100%;
        margin-top: 3rem;

        .philosophyTitle {
          color: var(--shishya-pink);
          font-weight: 700;
          text-decoration: underline;
          text-align: center;
          font-size: 2rem;
          margin-bottom: 3rem;
        }

        .philosophyContent {
          display: flex;
          flex-flow: row wrap;
          width: 100%;
          justify-content: space-around;

          .text {
            width: 45%;
            font-size: 1.2rem;
            flex-flow: column wrap;
            padding: 1rem;

            p:not(:first-child) {
              padding-top: 3rem;

              @include breakpoint(mobile) {
                padding-top: 0.5rem;
              }
            }

            @include breakpoint(mobile) {
              width: 100%;
            }
          }

          .image {
            width: 40%;

            img {
              width: 100%;
              height: 100%;
            }

            @include breakpoint(mobile) {
              width: 100%;
            }
          }

          @include breakpoint(mobile) {
            flex-flow: column-reverse wrap;
          }
        }
      }

      .missionContentTwo {
        width: 100%;
        margin: 3rem 0;

        .missionTitle {
          color: var(--shishya-blue);
          font-weight: 700;
          text-decoration: underline;
          text-align: center;
          font-size: 2rem;
          margin-bottom: 3rem;
        }

        .content {
          display: flex;
          flex-flow: row wrap;
          width: 100%;
          justify-content: space-evenly;

          .image {
            width: 30%;
            max-width: 400px;

            img {
              width: 100%;
              height: 100%;
            }

            @include breakpoint(mobile) {
              width: 100%;
            }
          }

          .text {
            width: 45%;
            font-size: 1.2rem;
            padding: 1rem;

            @include breakpoint(mobile) {
              width: 100%;
            }
          }

          @include breakpoint(mobile) {
            flex-flow: column wrap;
          }
        }
      }

      .missionContentThree {
        display: flex;
        flex-flow: row wrap;
        max-width: 100%;
        height: fit-content;
        background-color: var(--shishyaa-turquoise);
        justify-content: center;
        align-items: center;
        padding: 2rem;

        .text {
          width: 50%;
          font-size: 1.2rem;
          margin: 0.5rem;
          padding: 1rem;

          @include breakpoint(mobile) {
            width: 100%;
          }
        }

        .image {
          width: 45%;
          margin: 0.3rem;

          img {
            width: 100%;
            height: 100%;
          }

          @include breakpoint(mobile) {
            width: 100%;
            height: auto;
            margin: 0;
          }
        }

        @include breakpoint(mobile) {
          flex-flow: column-reverse nowrap;
        }
      }

      .missionContentFour {
        display: flex;

        .image {
          width: 50%;
          img {
            width: 100%;
            height: 100%;
          }

          @include breakpoint(desktop) {
            width: 30%;
          }

          @include breakpoint(mobile) {
            width: 95%;
          }
        }

        .text {
          height: 100%;
          padding: 1rem;
          width: 50%;

          @include breakpoint(desktop) {
            width: 60%;
          }

          @include breakpoint(mobile) {
            width: 95%;
          }
        }

        @include breakpoint(desktop) {
          flex-flow: row wrap;
          margin-top: 3rem;
          max-width: 1200px;
        }

        @include breakpoint(mobile) {
          flex-flow: column;
          width: 95vw;
          margin-top: 3rem;
        }
      }

      .missionContentFive {
        margin-top: 2rem;
        text-align: center;

        @include breakpoint(mobile) {
          width: 95vw;
        }

        @include breakpoint(desktop) {
          img {
            width: 40%;
          }
        }
      }

      @include breakpoint(mobile) {
        width: 95vw;
      }
    }
  }
}
