@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', 'Rancho';
  font-weight: 500;

  .body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    max-width: 1200px;
    margin: auto;

    .glimpsesContent {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;

      .glimpsesBannerImage {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .glimpsesMainContent{
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        justify-content: center;
        margin-top: 2rem;
        height: auto;

        .glimpsesTitle {
          color: var(--shishya-pink);
          text-decoration: underline;
          font-weight: 700;
          text-align: center;
          font-size: 2rem;
          margin-bottom: 3rem;

          @include breakpoint(mobile) {
            font-size: 1.5rem;
          }
        }

        .glimpsesContentOne {
          display: flex;
          flex-flow: row wrap;
          width: 100%;
          min-height: 80vh;

          .glimpsesVideos {
            display: flex;
            flex-flow: column wrap;
            width: 50%;
            justify-content: space-evenly;
            align-items: center;

            .videoOne, .videoTwo, .videoThree {
              width: 100%;
              height: 30%;
              text-align: center;

              iframe {
                max-width: 90%;
              }
            }

            @include breakpoint(mobile) {
              width: 100%;
            }
          }

          .glimpsesText {
            font-size: 1.2rem;
            width: 50%;
            display: flex;
            flex-flow: column wrap;
            justify-content: space-evenly;

            @include breakpoint(mobile) {
              width: 100%;
              font-size: 1rem;
            }
          }

          @include breakpoint(mobile) {
            flex-flow: column wrap;
          }
        }

        .glimpsesContentTwo {
          display: flex;
          flex-flow: column wrap;
          width: 70vw;
          justify-content: center;
          align-items: center;
          max-width: 1200px;

          .contentTitle {
            color: var(--shishya-blue);
            text-decoration: underline;
            font-weight: 700;
            text-align: center;
            font-size: 2rem;
            margin-bottom: 3rem;

            @include breakpoint(mobile) {
              font-size: 1.5rem;
            }
          }

          .image {
            width: 100%;

            img {
              width: 100%;
            }
          }

          @include breakpoint(mobile) {
            width: 100%;
          }
        }

        .glimpsesContentThree {
          display: flex;
          flex-flow: column wrap;
          width: 70vw;
          margin-top: 1.5rem;
          justify-content: center;
          align-items: center;
          max-width: 1200px;

          .contentTitle {
            color: var(--shishya-pink);
            text-decoration: underline;
            font-weight: 700;
            text-align: center;
            font-size: 2rem;
            margin-bottom: 3rem;

            @include breakpoint(mobile) {
              font-size: 1.5rem;
            }
          }

          .contentText {
            font-size: 1.2rem;

            @include breakpoint(mobile) {
              font-size: 1rem;
            }
          }

          @include breakpoint(mobile) {
            width: 100%;
          }
        }

        .glimpsesContentFour {
          width: 70vw;
          max-width: 1200px;
          .glimpsesBottomImageOne {
            width: 100%;

            img {
              width: 100%;
            }
          }

          .glimpsesQuote {

          }

          @include breakpoint(mobile) {
            width: 100vw;
          }
        }
      }

      @include breakpoint(desktop) {
        width: 70vw;
        max-width: 1200px;
      }

      @include breakpoint(mobile) {
        width: 95vw;
      }
    }
  }
}

