@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Rancho';
  font-weight: 500;

  .body {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;

    .banner {
      width: 70vw;
      margin-top: 2rem;
      max-width: 1200px;

      img {
        width: 100%;
        height: 100%;
      }

      @include breakpoint(mobile) {
        width: 95vw;
      }
    }

    .introText {
      font-size: 1rem;
      text-align: center;
      width: 70vw;
      margin-top: 2rem;
      max-width: 1200px;

      @include breakpoint(mobile) {
        margin-top: 1rem;
        width: 95vw;
      }
    }

    .contentOne {
      display: flex;
      flex-flow: column wrap;
      width: 70vw;
      margin-top: 1rem;
      max-width: 1200px;

      .splitContent {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 1.5rem;
        width: 100%;

        .imageContent {
          width: 50%;

          img {
            width: 100%;
            height: 100%;
          }

          @include breakpoint(mobile) {
            width: 100%;
          }
        }

        .textContent {
          width: 50%;
          padding: 1rem;

          @include breakpoint(mobile) {
            padding: 1rem;
            width: 100%;
          }
        }

        @include breakpoint(mobile) {
          margin-bottom: 1rem;
          width: 95vw;
          flex-flow: column-reverse wrap;
          justify-content: center;
        }
      }

      @include breakpoint(mobile) {
        width:100%;
      }
    }
  }
}

