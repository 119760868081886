@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Rancho';
  font-weight: 500;

  .body {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;

    .banner {
      width: 70vw;
      margin-top: 2rem;
      max-width: 1200px;

      img {
        width: 100%;
        height: 100%;
      }

      @include breakpoint(mobile) {
        width: 95vw;
      }
    }

    .mainContent {
      width: 80vw;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      max-width: 1200px;

      .text {
        width: 100%;
        padding: 1rem;

        @include breakpoint(mobile) {
          font-size: 1.2rem;
        }
      }

      .images {
        width: 80vw;
        display: flex;
        height: fit-content;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        max-width: 1200px;

        img {
          width: 50%;
          height: 50%;
          padding: 1px;
        }

        @include breakpoint(mobile) {
          display: flex;
          width: 100vw;
          flex-flow: column nowrap;

          img {
            width: 100%;
            height: 100%;
          }
        }

        @include breakpoint(mobile) {
          width: 100vw;
        }
      }

      @include breakpoint(mobile) {
        width: 95vw;
      }
    }
  }
}
