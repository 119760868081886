@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', 'Rancho';
  font-weight: 500;

  .body {

    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-top: 3rem;
    max-width: 1200px;
    margin: auto;

    .banner {
      @include breakpoint(desktop) {
        max-width: 70vw;
      }

      @include breakpoint(mobile) {
        width: 95vw;
        height: 30vh;

      }

    }

    .mainContent {
      max-width: 1200px;
      margin: auto;
      margin-top: 2rem;
      @include breakpoint(desktop) {
        max-width: 70vw;
      }

      @include breakpoint(mobile) {
        max-width: 90vw;
      }

      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: flex-start;
      font-size: 1.2rem;

      strong {
        margin-top: 2rem;
      }
    }

    .quote {
      margin-top: 2rem;
      text-align: center;

      height: auto;

      @include breakpoint(desktop) {
        width: 50%;
      }

      @include breakpoint(mobile) {
        width: 95%;
      }
    }
  }
}