@import "../../scss/_mixins.scss";

.header {
  width: 100%;
  padding: 0;
  margin: 0;
}

.headerLine1 {
  background-color: #a3f7f6;
  width: 100%;
  height: 6vh;
  padding: 0rem 1rem;
  display: flex;
  flex-flow: row wrap;
  font-size: 1rem;
  justify-content: space-around;
  align-content: center;

  .headerTitle {
    font-family: 'Rancho', sans-serif;
    font-size: 1.3rem;
    letter-spacing: 0.05rem;
  }
}

.headerLine2 {

  @include breakpoint(desktop) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
    height: 9vh;
    width: 100%;
  }

  @include breakpoint(mobile) {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }


  .headerIcon {
    height: 9vh;

    @include breakpoint(desktop) {
      width: 30%;
    }

    @include breakpoint(mobile) {
      width: 50%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

