@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', 'Rancho', sans-serif;
  font-weight: 500;

  .body {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    max-width: 1200px;
    margin: auto;

    .founderTitle {
      font-size: 2.5rem;
      font-weight: 800;
      text-align: center;
      color: var(--shishya-pink);
      text-decoration: underline;
    }
  }

}