@import '../../scss/_mixins.scss';

.teamMember {
  display: flex;
  align-items: center;
  font-family: "Open Sans", "Rancho", sans-serif;
  font-weight: 500;

  .memberPhoto {
    padding: 1rem;
    img {
      width: 100%;
      height: 100%;
    }

    @include breakpoint(mobile) {
      width: 72vw;
      height: 50vh;
    }

    @include breakpoint(desktop) {
      width: 22rem;
      height: 25rem;
    }
  }

  .memberDescription {
    padding: 1rem;
    .memberName {
      text-align: left;
      font-size: 1.5rem;
      font-weight: 800;
      margin-bottom: 1rem;
    }

    .memberRole {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }

    .memberTraining {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }

    .memberQualification {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }

    .memberAdditionalInfo {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }

    @include breakpoint(mobile) {
      width: 100vw;
    }

    @include breakpoint(desktop) {
      width: 50%;
    }
  }

  @include breakpoint(mobile) {
    flex-flow: column nowrap;
    width: 100vw;
    justify-content: center;
  }

  @include breakpoint(desktop) {
    flex-flow: row nowrap;
    width: 70vw;
    justify-content: center;
    max-width: 1200px;
    margin: auto;
  }
}


hr {
  border: 0;
  height: 1px;
  width: 50%;
  margin: 30px auto;
  position: relative;
  max-width: 1200px;

  &.divider {
    background: var(--shishya-blue);

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      background: var(--shishya-blue);
      display: inline-block;
      border: 1px solid var(--shishya-blue);
      position: absolute;
      left: 50%;
      transform:rotate(45deg);
      -ms-transform:rotate(45deg); /* IE 9 */
      -webkit-transform:rotate(45deg); /* Opera, Chrome, and Safari */
      top: -5px;
    }
  }

  @include breakpoint(mobile) {
    width: 80%;
  }
}

.hide {
  display: none;
}
