@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Rancho';
  font-weight: 500;

  .body {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;

    .banner {
      width: 70vw;
      max-width: 1200px;

      img {
        width: 100%;
        height: 100%;
      }

      @include breakpoint(mobile) {
        width: 90vw;
      }
    }

    .mainContent {
      width: 70vw;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      max-width: 1200px;

      .intro {
        font-size: 1rem;
        margin: 1rem;

        .curriculumLink {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .curriculumBody, .specialSubjectsBody {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        .heading {
          font-weight: bold;
          color: var(--shishya-pink);
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }

        .curriculumContent, .subjectsContent {
          display: flex;
          flex-flow: column wrap;
          justify-content: center;
          align-items: center;

          .splitContent {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            margin: 1rem 0;

            .textContent {
              width: 50%;
              font-size: 1rem;
              padding: 1rem;

              @include breakpoint(mobile) {
                width: 100vw;
              }
            }

            .imageContent {
              width: 50%;

              img {
                width: 100%;
                height: 100%;
              }

              @include breakpoint(mobile) {
                width: 100vw;
              }
            }

            @include breakpoint(mobile) {
              flex-flow: column;
              width: 100vw;

              &:nth-child(odd) {
                flex-flow: column-reverse wrap;
              }
            }

            &:nth-child(n) {
              @include breakpoint(mobile) {
                .textContent, .imageContent {
                  width: 95%;
                  padding: 0.5rem;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      @include breakpoint(mobile) {
        width: 100vw;
      }
    }
  }
}
