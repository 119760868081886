@import "../../scss/_mixins.scss";

.navigationContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
  width: 80vw;
  height: 13vh;
  max-height: 75px;

  .foundationName {
    font-size: 0.6rem;
    text-align: center;
    color: #1E7A4B;
    font-weight: 700;
  }

  nav {
    width: 100% ;
    z-index: 100;

    a {
      height: auto ;

      img {
        width: 100%;
        height: 100% ;
      }

      @include breakpoint(mobile) {
        width: 70%;
      }
    }
  }

  nav > div {
    width: 100%;
  }

  nav > a {
    width: 10vw;

    @include breakpoint(mobile) {
      width: 50vw;
    }

    @include breakpoint(tablet) {
      width: 50vw;
    }
  }

  nav > div > div {
    width: 100%;
    justify-content: flex-end;
  }

  nav > div > div > div {
    //margin-right: 1rem;
    @include breakpoint(desktop) {
      width: 120px;
    }
  }

  nav > button > span {
    width: 25px;
    height: 25px;
  }

  @include breakpoint(mobile) {
    display: block;
    width: 100vw;
  }

  @include breakpoint(tablet) {
    display: block;
    width: 100vw;
  }
}

.desktopContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5vw;
}

.navigationLink {

  @include breakpoint(desktop) {
    outline: none;
    margin-left: 3vw;

    a {
      text-decoration: none;
      color: #000000;
      font-weight: 500;

    }

    .academicsBtn {
      padding: .375rem .75rem;
      background-color: transparent;
      font-size: 1rem;
      font-weight: 500;
      border: 0;

      &:hover, &:focus-within {
        border: 1px solid #ddd;
        background-color: lightgray;
      }
    }
  }
}

.activeTab {
  background-color: var(--shishya-blue);
}

@include breakpoint(desktop) {
  #responsive-navbar-nav > div {
    width: 100% !important;
    justify-content: flex-end;
}
}

.homeBtn {
  @media only screen and (min-width: 1200px) {
    width: 120px;
  }
}

.marginLeft1 {
  margin-left: 1rem;
}

.mediaIcon {
  @media only screen  and (min-width: 768px){
    max-width: 30px ;
    max-height: 30px;
    //display: flex;
    //align-self: center;
  }
}

.mediaContainer {
  @media only screen and (min-width: 768px) {
    width: 130px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  @include breakpoint(mobile) {

    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;
  }
}

