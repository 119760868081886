.detailsContainer {
  width: 100%;
  max-width: 50rem;
  border-radius: 0.8rem;
  border: .1rem solid #fafafa;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.25);
  background-color: #f5f4f9;
  transition: all 1s ease;
  margin: 1rem 0;
  will-change: box-shadow;

  .summaryContainer {
    list-style: inside none;
    margin-left: 1rem;
    padding: 0.5rem 0;
    font-size: 1rem;
    font-weight: 600;
    max-width: 100%;
    position: relative;

    &::after {
      max-width: 100%;
      content: "\2192";
      right: 2rem;
      position: absolute;
    }
  }

  .divider {
    width: 90%;
    margin: 0 2rem;
    border: .1rem solid;
    color: #e1e1e1;
    background-color: currentColor;
  }

  .contentContainer {
    padding: 0.5rem;
  }
}

.detailsContainer[open] .summaryContainer::after {
  content: "\2193";
}
