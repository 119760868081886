@font-face {
    font-family: 'Open Sans Regular';
    src: url("../ui/fonts/Open_Sans/OpenSans-Regular.ttf") format("ttf");
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url("../ui/fonts/Open_Sans/OpenSans-Bold.ttf") format("ttf");
}

@font-face {
    font-family: 'Rancho';
    src: url("../ui/fonts/Rancho/Rancho-Regular.ttf") format("ttf");
}