@mixin breakpoint($size) {
  @if $size == mobile {
    @media (max-width: 486px) { @content; }
  }

  @else if $size == tablet {
    @media (min-width: 487px) and (max-width: 768px) { @content; }
  }

  @else if $size == tablet-landscape {
    @media (min-width: 1024px) and (max-width: 1024px) { @content; }
  }

  @else if $size == default {
    @media (min-width: 768px) { @content; }
  }

  @else if $size == desktop {
    @media (min-width: 769px) { @content; }
  }

  @else if $size == desktop-lg {
    @media (min-width: 1201px) { @content; }
  }

  @else if $size == desktop-xl {
    @media (min-width: 1681px) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: mobile, tablet, tablet-down, desktop and desktop-lg";
  }
}