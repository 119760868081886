@import "../../scss/_mixins.scss";

.mainPage {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', 'Rancho';
  font-weight: 500;

  .body {
    display: flex;
    flex-flow: column nowrap;
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;

    .mainPageSectionOne {
      max-width: 1200px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: center;

      .homePageCoverPhoto {
        position: relative;
        width: 100%;
        height: auto;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .homePageAlternateNavigation {
        position: absolute;
        display: flex;
        flex-flow: row wrap;

        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        max-width: 1200px;
        z-index: 100;

        @include breakpoint(mobile) {
          display: none;
        }

        @include breakpoint(tablet) {
          display: none;
        }

        @include breakpoint(desktop) {
          margin-bottom: -15rem;
        }

        @include breakpoint(desktop-lg) {
          margin-bottom: -15rem;
        }

        @include breakpoint(desktop-xl) {
          margin-bottom: -15rem;
        }
      }

      @include breakpoint(mobile) {
        width: 95vw;
      }
    }

    .mainPageSectionTwo {
      .aboutUsSection {
        margin-top: 20rem;
        max-width: 1200px;

        .blueSubtitle {
          font-size: unquote("clamp(1.5rem, -0.875rem + 8.333vw, 3.5rem)");;
          font-weight: 600;
          color: var(--shishya-blue);
          text-align: center;
        }

        .ourMissionSection {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          width: 100%;

          .missionContentLeftPane {
            width: 50%;
            padding: 2rem;

            @include breakpoint(mobile) {
              width: 100%;
              padding: 0;
            }
          }

          .missionContentRightPage {
            width: 50%;
            padding: 2rem;

            img {
              width: 100%;
              height: auto;
            }

            @include breakpoint(mobile) {
              width: 100%;
            }
          }

          @include breakpoint(mobile) {
            flex-flow: column wrap;
          }
        }

        @include breakpoint(mobile) {
          margin-top: 1rem;
          width: 95vw;
        }
      }
    }

    .mainPageSectionThree {
      width: 70vw;
      height: auto;
      max-width: 1200px;

      .curriculumSectionTitle {
        display: flex;
        flex-flow: column wrap;
        text-align: center;

        .curriculumSectionTitleHeader {
          color: var(--shishya-blue);
          font-weight: 600;
          font-size: unquote("clamp(1.5rem, -0.875rem + 8.333vw, 3.5rem)");
        }

        .curriculumSectionSubtitle {
          color: #000000;
          font-size: 1rem;
        }
      }

      .curriculumSectionBody {
        margin-top: 2rem;
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;

        .curriculumLeftPage {
          width: 50%;
          height: auto;
          cursor: pointer;

          .curriculumLeftPaneTitle {
            font-size: 1.2rem;
            font-weight: 400;
          }

          .curriculumLeftPaneBody {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            height: fit-content;
            width: 100%;

            .curriculumBodyText {
              font-size: 1rem;
            }

            .curriculumBodyImage {
              width: 100%;
              height: 100%;
              text-align: center;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          @include breakpoint(mobile) {
            width: 100%;
            height: fit-content;
          }
        }

        .curriculumRightPage {
          width: 50%;
          height: auto;
          cursor: pointer;

          .curriculumRightPaneTitle {
            font-size: 1.2rem;
            font-weight: 400;
          }

          .curriculumRightPaneBody {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            height: fit-content;
            width: 100%;

            .curriculumBodyText {
              font-size: 1rem;
            }

            .curriculumBodyImage {
              width: 100%;
              height: 100%;
              text-align: center;

              img {
                width: 100%;
                height: 100%;
              }

              @include breakpoint(mobile) {
                height: 75%;
              }
            }
          }

          @include breakpoint(mobile) {
            width: 100%;
            height: fit-content;
            margin-top: 3rem;
          }
        }

        @include breakpoint(mobile) {
          flex-flow: column wrap;
        }
      }

      @include breakpoint(mobile) {
        width: 95vw;
      }
    }

    .mainPageSectionFour {
      height: fit-content;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      max-width: 1200px;
      background-color: var(--shishyaa-turquoise);
      margin-bottom: 1rem;

      .mainPageSectionFourLeftPane {
        width: 50%;
        height: 100%;

        img {
          width: 100%;
          height: auto;
        }

        @include breakpoint(mobile) {
          width: 100%;
        }
      }

      .mainPageSectionFourRightPane {
        width: 50%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        background-color: var(--shishyaa-turquoise);
        height: 100%;

        .mainPageSectionFourTitle {
          font-weight: bolder;
          font-size: 2rem;
        }

        .mainPageAdmissionButton {
          width: 8rem;
          height: 2rem;
          margin-top: 1rem;
          text-decoration: none;
          background-color: #ffffff;
          border-radius: 0.5rem;
          box-shadow: 10px 5px 5px lightgray;
          color: #000000;
          text-align: center;
          font-weight: 600;
          padding-top: 0.3rem;
        }

        @include breakpoint(mobile) {
          width: 100%;
          padding: 1rem;
        }

        @include breakpoint(desktop) {
          height: 100%;
        }
      }

      @include breakpoint(mobile) {
        flex-flow: column wrap;
        width: 100%;
        height: auto;
      }
    }
  }

  .showGotoTopBtn {
    right: 10px;
    bottom: 10px;
    position: fixed;
    z-index: 100;
    background-color: grey;
    border-radius: 50%;
  }
}
